import React, {useEffect, useMemo, useCallback, useState} from 'react';
import {debounce} from 'lodash';

import './App.css';
import logoNav from './logoNav.png';
import moment from "moment";
import * as Realm from "realm-web";
import type {
    APIConnector,
    AutocompleteQueryConfig,
    AutocompleteResponseState,
    QueryConfig,
    RequestState,
    ResponseState,
} from "@elastic/search-ui";
import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    SearchBox,
    Result,
    Results,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting,
    WithSearch
} from "@elastic/react-search-ui";
import {
    BooleanFacet,
    Layout,
    SingleLinksFacet,
    SingleSelectFacet,
} from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

export const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

function relativeTimeFormat(t: moment.Moment) {
    // Using Intl.RelativeTimeFormat, provide the relative time in:
    // - seconds, if less than 60 seconds
    // - minutes, if less than 60 minutes
    // - hours, if less than 24 hours
    // - days, if less than 30 days
    // - months, if less than 12 months
    // - years, otherwise
    const now = moment();
    const rtf = new Intl.RelativeTimeFormat("en", {numeric: "auto"});
    const seconds = now.diff(t, "seconds");
    if (seconds < 60) {
        return rtf.format(-seconds, "second");
    }
    const minutes = now.diff(t, "minutes");
    if (minutes < 60) {
        return rtf.format(-minutes, "minute");
    }
    const hours = now.diff(t, "hours");
    if (hours < 24) {
        return rtf.format(-hours, "hour");
    }
    const days = now.diff(t, "days");
    if (days < 30) {
        return rtf.format(-days, "day");
    }
    const months = now.diff(t, "months");
    if (months < 12) {
        return rtf.format(-months, "month");
    }
    const years = now.diff(t, "years");
    return rtf.format(-years, "year");

}

class MyAPIConnector implements APIConnector {
    private app: Realm.App;
    private lastSearchParams: any;
    private lastSearchResults: any;

    constructor(app: Realm.App) {
        this.app = app;
    }

    async ensureLoggedIn() {
        if (!this.app.currentUser) {
            await this.app.logIn(Realm.Credentials.anonymous());
        }
    }

    async onSearch(
        state: RequestState,
        queryConfig: QueryConfig
    ): Promise<ResponseState> {
        await this.ensureLoggedIn();
        const {
            searchTerm,
            current = 1,
            filters = [],
            sort,
            resultsPerPage = 40,
            sortDirection,
            sortField,
            sortList
        } = state;
        let remote = false;
        let location = "";
        let tags: any = [];
        let employers: any = [];
        const lastSearchResults = this.lastSearchResults;
        const lastSearchParams = this.lastSearchParams;
        for (let filter of filters) {
            if (filter.field === "remote" && filter.values.length > 0) {
                remote = filter.values[0] === "true";
            } else if (filter.field === "location" && filter.values.length > 0) {
                location = filter.values[0] as string;
            } else if (filter.field === "tags") {
                tags = filter.values;
            } else if (filter.field === "employers") {
                employers = filter.values;
            }
        }

        const results = [];
        const params: any = {
            q: searchTerm,
            skip: resultsPerPage * (current - 1),
            limit: resultsPerPage
        }
        if (remote) {
            params.remote = true;
        }
        if (sortList && sortList.length > 0) {
            params.sort = sortList;
        }
        if (location) {
            params.location = location;
        }
        if (tags && tags.length > 0) {
            params.tags = tags;
        }
        if (employers && employers.length > 0) {
            params.employers = employers;
        }
        let rawResults;
        if (JSON.stringify(params) !== JSON.stringify(this.lastSearchParams)) {
            try {
                rawResults = await this.app.currentUser?.functions.jobsSearch(params);
            } catch (e) {
                console.log("Exception occured", e);
                // @ts-ignore
                if (/invalid session/.test(e.message)) {
                    console.log("Session expired, trying to log in again");
                    // Session expired, try to log in again
                    await this.app.currentUser?.logOut();
                    await this.ensureLoggedIn();
                }
                console.log("Retrying search");
                rawResults = await this.app.currentUser?.functions.jobsSearch(params);
            }
            this.lastSearchParams = params;
            this.lastSearchResults = rawResults;
        } else {
            rawResults = this.lastSearchResults;
        }
        if (rawResults && rawResults.jobs) {
            for (let rawResult of rawResults.jobs) {
                // const entryCreated = moment(rawResult.created);
                // const listingCreated = moment(rawResult.listing.created || rawResult.created);
                // const listingUpdated = moment(rawResult.listing.updated || rawResult.created);
                // const discovered = moment.min(entryCreated, listingCreated, listingUpdated);
                const discovered = moment(rawResult.created);
                const url = rawResult.url;
                const doc = {
                    id: {
                        raw: rawResult._id
                    },
                    title: {
                        raw: rawResult.listing.title
                    },
                    employer: {
                        raw: rawResult.employer.name
                    },
                    discovered: {
                        raw: relativeTimeFormat(discovered)
                    },
                    url: {
                        raw: url,
                    },
                    location: {
                        raw: rawResult.listing.location
                    },
                    image_url: {
                        raw: rawResult.employer.imageUrl
                    }
                };
                // Remove any empty fields
                const cleanDoc: any = {};
                for (let key in doc) {
                    // @ts-ignore
                    if (doc[key].raw) {
                        // @ts-ignore
                        cleanDoc[key] = doc[key];
                    }
                }
                // Used to track clicks
                cleanDoc._meta = {
                    id: doc.id.raw,
                }
                results.push(cleanDoc);
            }
        }

        let lowerBound = rawResults?.meta?.count?.lowerBound || 0;
        const pagingStart = resultsPerPage * (current - 1) + Math.min(1, results.length);
        const pagingEnd = resultsPerPage * (current - 1) + results.length;
        const hasMore = results.length === resultsPerPage;
        const totalResults = hasMore ? Math.max(lowerBound || 0, pagingEnd) : pagingEnd;
        let totalPages = hasMore ? totalResults / resultsPerPage : current;

        const facets = {
            "remote": [
                {
                    "data": [
                        {
                            "value": "true"
                        }
                    ],
                    "name": "remote"
                }
            ],
            "location": [
                {
                    "data": [
                        {
                            "value": location
                        }
                    ],
                    "name": "location"
                }
            ],
            "tags": [
                {
                    "data": [],
                }
            ],
            "employers": [
                {
                    "data": [],
                }
            ]
        }

        let remoteCount;

        for (let bucket of rawResults?.meta?.facet?.tags?.buckets || []) {
            if (bucket._id === "remote") {
                remoteCount = bucket.count;
                continue;
            }
            // @ts-ignore
            facets.tags[0].data.push({
                "value": bucket._id,
                "count": bucket.count
            })
        }

        for (let bucket of rawResults?.meta?.facet?.employers?.buckets || []) {
            // @ts-ignore
            facets.employers[0].data.push({
                "value": bucket._id,
                "count": bucket.count
            })
        }

        if (remoteCount) {
            // @ts-ignore
            facets.remote[0].data[0].count = remoteCount;
        }


        window.scrollTo(0, 0);
        return {
            pagingStart,
            pagingEnd,
            rawResponse: undefined,
            resultSearchTerm: (searchTerm || "").trim(),
            totalResults,
            totalPages,
            results: results,
            wasSearched: true,
            requestId: "request-" + moment().format("YYYY-MM-DD HH:mm:ss"),
            facets: facets
        }
    }

    async onAutocomplete(
        state: RequestState,
        queryConfig: AutocompleteQueryConfig
    ): Promise<AutocompleteResponseState> {
        await this.ensureLoggedIn();
        // const response = await fetch(
        //     "https://api.my-host/autocomplete?query" + state.searchTerm,
        //     {
        //         headers: {
        //             "Content-Type": "application/json"
        //         }
        //     }
        // );
        // // response will need to be in the shape of AutocompleteResponseState.
        // // Alternatively you could transform the response here
        // return response.json();
        const {searchTerm} = state;
        const suggestions = [];
        let rawSuggestions;
        try {
            rawSuggestions = await this.app.currentUser?.functions.autocompleteJobTitle({q: searchTerm});
        } catch (e) {
            console.log("Autocomplete exception occured", e);
            // @ts-ignore
            if (/invalid session/.test(e.message)) {
                console.log("Session expired, trying to log in again");
                // Session expired, try to log in again
                await this.app.currentUser?.logOut();
                await this.ensureLoggedIn();
            }
            console.log("Retrying search");
            rawSuggestions = await this.app.currentUser?.functions.autocompleteJobTitle({q: searchTerm});
        }
        if (rawSuggestions) {
            for (let rawSuggestion of rawSuggestions) {
                suggestions.push(
                    {
                        "suggestion": String(rawSuggestion)
                    }
                );
            }
        }
        return {
            autocompletedResults: [],
            autocompletedResultsRequestId: "request-" + moment().format("YYYY-MM-DD HH:mm:ss"),
            autocompletedSuggestions: {
                suggestions: suggestions,
            },
            autocompletedSuggestionsRequestId: "suggestions-" + moment().format("YYYY-MM-DD HH:mm:ss"),
        }
    }

    onResultClick(params: any): void {
        this.app.currentUser?.functions.action({
            action: "click",
            type: "job",
            id: params.result._meta.id,
        });
    }

    onAutocompleteResultClick(params: any): void {
        // console.log(
        //     "perform a call to the API to highlight an autocomplete result has been clicked"
        // );
    }
}

const app = new Realm.App({id: "application-0-wxxfl"});

const connector = new MyAPIConnector(app);

const config = {
    debug: isDev(),
    alwaysSearchOnInitialLoad: true,
    apiConnector: connector,
    hasA11yNotifications: true,
    searchQuery: {},
    autocompleteQuery: {},
};

const SORT_OPTIONS = [
    {
        name: "Relevance (Default)",
        value: []
    },
    {
        name: "Date",
        value: [
            {
                field: "created",
                direction: "desc"
            }
        ]
    },
];

const hiddenFields = ['image_url', 'id', 'url', 'title'];

function hideFields() {
    document.addEventListener('DOMNodeInserted', function (event) {
        // @ts-ignore
        if (event.target && event.target.className === 'sui-result') {
            // @ts-ignore
            const resultKeys = event.target.getElementsByClassName('sui-result__key');
            for (let i = 0; i < resultKeys.length; i++) {
                const key = resultKeys[i];
                // @ts-ignore
                if (key.textContent && hiddenFields.includes(key.textContent)) {
                    // @ts-ignore
                    if (key.parentElement)
                        // @ts-ignore
                        key.parentElement.style.display = 'none';
                }
            }
        }
    });
}

function LocationFacet({parentProps}: { parentProps: any }) {
    const [value, setValue] = useState(parentProps.values[0] || '');

    const changeHandler = useCallback(
        (e: any) => {
            parentProps.onChange(e.target.value);
        },
        [parentProps.onChange]
    );

    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), [
        changeHandler,
    ]);

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, [changeHandler]);

    return (
        <fieldset className="sui-facet">
            <legend className="sui-facet__title">Location</legend>
            <div className="sui-boolean-facet">
                <div className="sui-boolean-facet__option-input-wrapper">
                    <label className="sui-boolean-facet__option-label">
                        <div className="sui-boolean-facet__option-input-wrapper">
                            <input
                                data-transaction-name="facet - Location"
                                className="sui-search-box__text-input"
                                type="text"
                                placeholder="Search by location"
                                onInput={(e) => {
                                    debouncedChangeHandler(e);
                                    // @ts-ignore
                                    setValue(e.target.value);
                                }}
                                value={value}
                            />
                        </div>
                        <span className="sui-boolean-facet__option-count"></span>
                    </label>
                </div>
            </div>
        </fieldset>
    );
}


class Footer extends React.Component {
    render() {
        return <footer className="footer">
            <p className="footer-link">
                <a href="/privacy" target="_blank">Privacy Policy</a>
            </p>
            <p className="footer-link">
                <a href="/cookies" target="_blank">Cookie Policy</a>
            </p>
            <p className="footer-link">
                <a href="https://clearbit.com" target="_blank" rel="noopener noreferrer">Logos provided by
                    Clearbit</a>
            </p>
        </footer>;
    }
}

class Header extends React.Component {
    render() {
        return <div className="header-container">
            <a href="/">
                <img src={logoNav} className="header-image" alt="JobHelmet"/>
            </a>
            <div className="search-box-container">
                {this.getSearchBox()}
            </div>
            {/*<div className="user-auth-container">*/}
            {/*    <a href="/register" className="user-auth-link">Connect</a>*/}
            {/*    /!*<span className="user-auth-separator">|</span>*!/*/}
            {/*    /!*<a href="/login" className="user-auth-link">Login</a>*!/*/}
            {/*</div>*/}
        </div>;
    }

    private getSearchBox() {
        return <SearchBox
            autocompleteMinimumCharacters={1}
            autocompleteResults={{
                linkTarget: "_blank",
                sectionTitle: "Results",
                titleField: "title",
                urlField: "url"
            }}
            autocompleteSuggestions={{
                sectionTitle: "Suggestions",
                displayField: "title",
            }}
            debounceLength={0}
            inputProps={{
                placeholder: "Search for jobs",
                autoFocus: true,
            }}
            shouldClearFilters={false}
        />;
    }
}

function getSearchProvider() {
    return <SearchProvider config={config}>
        <WithSearch
            mapContextToProps={({wasSearched}) => ({
                wasSearched
            })}
        >
            {({wasSearched}) => {
                return (
                    <div className="App">
                        <ErrorBoundary>
                            <Layout
                                header={
                                    <Header/>
                                }
                                sideContent={
                                    <div>
                                        {/*{wasSearched && (*/}
                                        {/*    <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS}/>*/}
                                        {/*)}*/}
                                        <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS}/>
                                        <Facet
                                            field="remote"
                                            label="Remote"
                                            view={BooleanFacet}
                                        />
                                        {/* location search filter */}
                                        <Facet
                                            field="location"
                                            label="Location"
                                            view={(props) => {
                                                // @ts-ignore
                                                return <LocationFacet parentProps={props}/>
                                            }}
                                        />
                                        <Facet
                                            field="tags"
                                            label="Tags"
                                        />
                                        <Facet
                                            field="employers"
                                            label="Employer"
                                        />
                                    </div>
                                }
                                bodyContent={
                                    <Results
                                        titleField="title"
                                        urlField="url"
                                        thumbnailField="image_url"
                                    />
                                }
                                bodyHeader={
                                    <React.Fragment>
                                        {wasSearched && <PagingInfo/>}
                                        {wasSearched && <ResultsPerPage/>}
                                    </React.Fragment>
                                }
                                bodyFooter={
                                    <Paging/>
                                }
                            />
                        </ErrorBoundary>
                    </div>
                );
            }}
        </WithSearch>
    </SearchProvider>;
}

export default function App() {
    useEffect(() => {
        hideFields();
    }, []);

    return (
        <main>
            {getSearchProvider()}
            <Footer/>
        </main>
    );
}
